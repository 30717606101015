// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-book-now-kempsville-location-jsx": () => import("./../../../src/pages/book-now-kempsville-location.jsx" /* webpackChunkName: "component---src-pages-book-now-kempsville-location-jsx" */),
  "component---src-pages-book-now-pembroke-location-jsx": () => import("./../../../src/pages/book-now-pembroke-location.jsx" /* webpackChunkName: "component---src-pages-book-now-pembroke-location-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-join-our-team-jsx": () => import("./../../../src/pages/join-our-team.jsx" /* webpackChunkName: "component---src-pages-join-our-team-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

